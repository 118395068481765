import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'
import Meta from 'vue-meta'
import global from '@/utlis/global'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'

Vue.prototype.$global = global
Vue.prototype.$server = global.server

if (typeof window !== 'undefined') {
	// 此代码块只在客户端执行
	const firebaseConfig = global.firebaseConfig // 从 window 对象获取 Firebase 配置
	let app = null
	let analytics = null

	isSupported().then((result) => {
		if (result) {
			app = initializeApp(firebaseConfig)
			analytics = getAnalytics(app)
		}
	})

	Vue.prototype.$logEvent = (event, params = {}) => {
		console.log(event)
		isSupported().then((result) => {
			if (result && analytics) {
				// 检查 analytics 是否已初始化
				logEvent(analytics, event, params)
			}
		})
	}
} else {
	// 对于服务器端，你可以定义一个简单的函数或者不做任何事情
	Vue.prototype.$logEvent = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
}

// simple event
Vue.use(Meta)

export default function createApp(baseURL) {
	const router = createRouter(baseURL)
	const store = createStore()
	const app = new Vue({
		router,
		store,
		render: (h) => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
