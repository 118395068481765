// src/router/index.js
import Vue from 'vue'
import Router from 'vue-router'
import Privacy from '@/pages/privacy'
import BlogIndex from '@/pages/blogIndex'
Vue.use(Router)

const domainMappings = {
	'gbwhatsapps.chat': 'domain-1',
	'gbwhatsapk.org': 'domain-2',
	'gbwhatsapk.net': 'domain-3',
	'gbapks.com.in': 'domain-4',
	'gbapks.com': 'domain-5',
	'gbapks.org.in': 'domain-6',
	'gbapks.click': 'domain-7',
	'waplusapk.click': 'domain-8',
	'waplusapk.download': 'domain-9',
	'waproapk.com': 'domain-10',
	'waproapk.net': 'domain-11',
	'whatsapppro.org': 'domain-12',
	'whatsappapk.download': 'domain-13',
	'gbapps.homes': 'domain-15',
	localhost: 'domain-12',
}
const blogMappings = {
	// 'gbapks.com': 'blog-1',
	// 'gbwhatsapk.net': 'blog-2',
	// localhost: 'blog-1',
	'gb-whatsapp-download': 'blog-1',
	// 'gbwhatsapp-pro-download'
}

function resolveComponent(componentPath) {
	if (process.server) {
		return require(`@/components/home/${componentPath}.vue`).default
	} else {
		return (resolve) => require([`@/components/home/${componentPath}.vue`], resolve)
		// return () => import(`@/components/home/${componentPath}.vue`)
	}
}

function DynamicDomainComponent(baseURL) {
	console.log(`home router.js打印的url地址 用于判断渲染文件>>${baseURL}`)
	const key = Object.keys(domainMappings).find((domain) => baseURL.includes(domain))
	const component = domainMappings[key] || 'domain-12'
	return resolveComponent(component)
}

function resolveBlogComponent(componentPath) {
	if (process.server) {
		return require(`@/components/blogs/${componentPath}.vue`).default
	} else {
		// return () => import(`@/components/blogs/${componentPath}.vue`)
		return (resolve) => require([`@/components/blogs/${componentPath}.vue`], resolve)
	}
}

function DynamicDomainComponentBlog(baseURL) {
	console.log(`blog router.js打印的url地址 用于判断渲染文件>>${baseURL}`)
	const key = Object.keys(blogMappings).find((domain) => baseURL.includes(domain))
	const component = blogMappings[key] || 'blog-1'
	return resolveBlogComponent(component)
}


export default function createRouter(baseURL = '') {
	const baseRoutes = [{
		path: '/',
		name: 'home',
		component: DynamicDomainComponent(baseURL),
	}]
	if (baseURL.indexOf('gbapks.com') >= 0 && baseURL.indexOf('gbapks.com.in') < 0) {
		baseRoutes.push(
			{
				path: '/blogs',
				name: 'blogs',
				component: BlogIndex,
			},
			{
				path: '/privacy',
				name: 'privacy',
				component: Privacy,
			},
			{
				path: '/gb-whatsapp-download',
				component: () => import('@/components/blogs/blog-1.vue'),
			},
			{
				path: '/gbwhatsapp-pro-download',
				component: () => import('@/components/blogs/blog-2.vue'),

			},
			{
				path: '/use-gbwhatsapp-without-getting-ban',
				component: () => import('@/components/blogs/blog-3.vue')
			},
			{
				path: '/difference-between-gbwhatsapp-whatsapp',
				component: () => import('@/components/blogs/blog-4.vue')
			},
			{
				path: '/change-gbwhatsapp-themes',
				component: () => import('@/components/blogs/blog-5.vue')
			},
			{
				path: '/gbwhatsapp-web-desktop',
				component: () => import('@/components/blogs/blog-6.vue')
			},
			{
				path: '/5-messaging-apps',
				component: () => import('@/components/blogs/blog-7.vue')
			}
		)
	}
	if (baseURL.indexOf('whatsapppro.org') >= 0) {
		baseRoutes.push(
			{
				path: '/id',
				component: () => import('@/components/home/domain-12id.vue'),
			}
		)
	}

	// baseRoutes.push(
	// 	{
	// 		path: '/id',
	// 		component: () => import('@/components/home/domain-12id.vue'),
	// 	}
	// )
	return new Router({
		mode: 'history',
		routes: baseRoutes,
	})
}
