<template>
  <div id="footer">
    <div class="footer-row">
      <img src="@/assets/GBPRO@2x.png" class="footer-img" alt="logo">
      <div class="footer-name">
        GB WhatsApp
      </div>
    </div>

    <div class="copyright">
      <p>Copyright © 2024 <a href="/">GB WhatsApp</a><sup>®</sup>. All rights reserved. </p>
    </div>

    <!-- <div class="terms-lang-switch">
      <ul class="terms list-inline-w-separator">
        <li>
          <a href="/privacy-policy" title="Privacy Policy">
            Privacy Policy
          </a>
        </li>
        <li>
          <a href="/terms-of-use" title="Terms of Use">
            Terms of Use
          </a>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'FooterComponent'
};
</script>
