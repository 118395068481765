<template>
	<div id="head">
		<div
			class="head-logo-row"
			@click="$router.push('/')"
			style="cursor: pointer">
			<img
				src="../assets/GBPRO@2x.png"
				class="head-img"
				alt="logo" />
			<div class="head-name">GB WhatsApp</div>
		</div>
		<div
			id="nav-row"
			class="nav-row">
			<div class="nav-item">
				<a
					class="nocolor"
					href="/">
					Home
				</a>
			</div>
			<div
				class="nav-item"
				v-if="isValidHost">
				<a
					class="nocolor"
					href="/blogs">
					Blog
				</a>
			</div>
			<div class="nav-item">
				<a
					class="nocolor"
					:href="apk">
					Download
				</a>
			</div>
			<div
				class="nav-item"
				v-if="isValidHost">
				<a
					class="nocolor"
					href="/privacy">
					Privacy
				</a>
			</div>
			<div v-if="validSite(['whatsapppro.org'])" class="nav-item lang">
				<div
					@click="show"
					class="displayitem">
					<img v-if="deviceType !=='mobile'"
						:src="require('../assets/' + countryMap[curLang] + '.png')"
						alt="" />
					<a class="nocolor">{{ curLang }}</a>
					<svg
						viewBox="0 0 330 512"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						width="1em"
						height="1em">
						<path
							fill="#fff"
							d="M305.913 197.085c0 2.266-1.133 4.815-2.833 6.514L171.087 335.593c-1.7 1.7-4.249 2.832-6.515 2.832s-4.815-1.133-6.515-2.832L26.064 203.599c-1.7-1.7-2.832-4.248-2.832-6.514s1.132-4.816 2.832-6.515l14.162-14.163c1.7-1.699 3.966-2.832 6.515-2.832 2.266 0 4.815 1.133 6.515 2.832l111.316 111.317 111.316-111.317c1.7-1.699 4.249-2.832 6.515-2.832s4.815 1.133 6.515 2.832l14.162 14.163c1.7 1.7 2.833 4.249 2.833 6.515z"></path>
					</svg>
				</div>
				<div class="dropdown">
					<div
						class="item"
						v-for="item in dropdownArr"
						:key="item">
						<img 
							:src="require('../assets/' + countryMap[item] + '.png')"
							alt="" />
						<a	
							@click="goto(item)"
							class="nocolor"
							href="javascript:void(0);">
							{{ item }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'TopheadComponent',
	data() {
		return {
			curLang: 'ENGLISH',
			countryMap: {
				ENGLISH: 'us',
				'Bahasa Indonesia': 'id',
			},
			dropdownArr: ['ENGLISH', 'Bahasa Indonesia'],
			showState: false
		}
	},
	created() {
		if (typeof localStorage =='undefined')return 
		console.log(22222, this.host)
		let preLang = localStorage.getItem('lang')
		console.log('pre', preLang)
		if (preLang && preLang.length) {
			this.curLang = preLang
		}
		this.dropdownArr.splice(0, this.dropdownArr.length, ...this.dropdownArr.filter((e) => e !== this.curLang))
	},
	props: {
		apk: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapGetters(['showDebug', 'host']),
		isValidHost() {
			const validLocalhost = /^localhost(:\d+)?$/
			const validDomain = /^(https?:\/\/)?(www\.)?gbapks\.com$/
			if (!this.host) {
				return false
			}
			return validLocalhost.test(this.host) || validDomain.test(this.host)
		},

		deviceType() {
			return this.$store.state.deviceType
		}
	},
	methods: {
		validSite (arr) {
			console.log(arr);
			if (this.host.length === 0) return true
			return arr.some(e => {
				return this.host.indexOf(e) >=0
			})
		},
		show() {
			if ( this.deviceType!== 'mobile') {
				return
			}
			this.showState = !this.showState
			if (this.showState){
			document.querySelector('.dropdown').classList.add('showCountry')}
			else {
				document.querySelector('.dropdown').classList.remove('showCountry')}

			
		},
		goto(item) {
			if (typeof localStorage =='undefined')return 
			localStorage.setItem('lang', item)

			if (item === 'ENGLISH') {
				window.location.href = '/'
				return
			}
			window.location.href = '/' + this.countryMap[item]
		},
	},
}
</script>
<style lang="scss" scoped>
</style>